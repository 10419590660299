    import React from 'react';
    import "./Footer2.css";
    
    export default function Footer2() {
      return (
        <div className="main-cnt">
            <p className="footer-txt">Tax ID: 04-3720418<br/> 
            A fund of the Davis Memorial Fun</p>
        </div>
      )
    }
    