import React, { useContext, useEffect } from "react";
import "./Navbar.css";
import dmfLog from "../images/dmfLog.svg";
import burgerBlue from "../images/burgerBlue.svg";

import { ThemeContext } from "../utils/NavbarContextProvider";
function Navbar() {
  const { isNavBarOpen, setIsNavBarOpen, setIsManuallyClosed } =
    useContext(ThemeContext);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 800) {
        setIsNavBarOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  });

  function handleClose() {
    setIsManuallyClosed(false);
    setIsNavBarOpen((prev) => !prev);
  }

  function handleLinkClick() {
    setIsNavBarOpen(false);
  }

  const handleClick = () => {
    window.location.href = "https://dmfcares.org/";
  };

  const handleContactClick = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
      setIsNavBarOpen(false);
    }
  };

  return (
    <section
      style={{
        maxWidth: "1900px",
        margin: "0 auto",
        width: "100%",
      }}
    >
      <nav className="navbar">
        <div
          className="navbar-logo"
          style={{
            width: "11rem",
          }}
        >
          <img
            // src={dmfLog}
            alt="Logo"
            style={{
              width: "80%",
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
        </div>

        <ul className={`navbar-links ${isNavBarOpen ? "show" : "hide"}`}>
          <li className="navbarLink">
            <a
              href="https://www.nedivoslir.org/blank-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Our Mission
            </a>
          </li>
          <li className="navbarLink" onClick={handleLinkClick}>
            <a
              href="https://www.nedivoslir.org/blank-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              Projects
            </a>
          </li>
          <li className="navbarLink" onClick={handleContactClick}>
            <a
              href="https://www.nedivoslir.org/blog"
              target="_blank"
              rel="noopener noreferrer"
            >
              Updates
            </a>
          </li>
          <li className="navbarLink" onClick={handleContactClick}>
            <a
              href="https://www.nedivoslir.org/join-your-community-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Your Community
            </a>
          </li>
          <li className="navbarLink" onClick={handleContactClick}>
            <a
              href="https://www.nedivoslir.org/blank-4"
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQ
            </a>
          </li>
          <li className="navbarLink" onClick={handleContactClick}>
            <a
              href="https://nedivos.dmfcares.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Donate
            </a>
          </li>
          <li className="navbarLink" onClick={handleContactClick}>
            <a
              href="https://www.nedivoslir.org/endorsments"
              target="_blank"
              rel="noopener noreferrer"
            >
              Endorsments
            </a>
          </li>
        </ul>

        <div className="navbar-menu-icon" onClick={handleClose}>
          <img
            style={{
              width: "45px",
              height: "40px",
            }}
            src={burgerBlue}
            alt="Menu Icon"
          />
        </div>
      </nav>
    </section>
  );
}

export default Navbar;
