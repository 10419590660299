import React from "react";
import Footer from "../components/Footer";
import QuoteSection from "../components/QuoteSection";

import Contact from "../components/Contact";
import Navbar from "../components/Navbar";
import "./SuccessPage.css";
import NavbarContextProvider from "../utils/NavbarContextProvider";

import hero from "../images/nediv5.png";

export default function SuccessPage() {
  return (
    <NavbarContextProvider>
      <div className="donatePage">
        {/* <Navbar /> */}
        {/* <section className="heroSection">
          <img src={hero} className="heroImg"></img>
        </section> */}

        <section className="succes-cont">
          <h1>Thank you for</h1>
          <h1>bringing dignified relief</h1>
          <h1>to a local family.</h1>

          <h2>Your donation has been submitted.</h2>

          <h3>You will receive a confirmation email shortly. </h3>
        </section>

        <QuoteSection
          backgroundColor={"rgba(255, 157, 137, 0.45)"}
          textColor={"#131f67"}
        >
          “I once learned that one isn't supposed to give specific praise about
          Hashem since once you start you can't stop. If you stop, "is that it?"
          On the same note, I feel like even if I sent you a thank you email
          daily that would be insufficient. "Is that it?" Just once a day? It's
          hard to describe how helpful your monthly and yom tov help is. We
          appreciate it so much. You're doing such great work and are helping
          more than you know. THANK YOU!!!”
        </QuoteSection>

        {/* <section
          id={"contact"}
          style={{
            maxWidth: "1900px",
            margin: "0 auto",
            width: "75%",
          }}
        >
          <Contact />
        </section> */}

        <div
          style={{
            marginBottom: "100px",
          }}
        ></div>
        <Footer />
      </div>
    </NavbarContextProvider>
  );
}
